import { useEffect, useState } from 'react';
import { getLocations, getLocation } from './api/locations';
import { getProducts } from './api/products';
import { redirectToLogin } from './api/identity';
import { cleanName } from './utils/index';
import { getZipCodeFromGeoLocation, getDistanceInMiles } from './utils/geolocation';
import './App.scss';

const App = () => {
  const defaultLocation = localStorage.getItem('locationId');
  const [products, setProducts] = useState([]);
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState(defaultLocation);
  const [defaultStore, setDefaultStore] = useState({});
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [zip, setZip] = useState();

  useEffect(() => {
    //return getLocations(92101).then((response) => { setLocations(response.data.data); console.log(response.data.data); });
    
    getLocation(location).then((response) => {
      setDefaultStore(response.data.data.address);
    }).then(() => {
      getProducts().then((response) => {
        setProducts(response);
      });
    });
  }, [location]);

  const handleLocationChange = (locationId) => {
    localStorage.removeItem("locationId");
    getLocation(locationId).then((response) => {
      setToggleDrawer(!toggleDrawer);
      setDefaultStore(response.data.data.address);
      localStorage.setItem("locationId", locationId);
      setLocation(locationId);
    });
  }

  const handleDrawer = () => {
    setToggleDrawer(!toggleDrawer)
  }

  const handleGeoLocation = () => {
    getZipCodeFromGeoLocation()
    .then((response) => { 
      setZip(response);
      getLocations(response)
        .then((response) => {
          setLocations(response.data.data)
          console.log(response.data.data);
        }) 
    });
  }

  const handleZipCode = (ev) => {
    getLocations(ev.target.value).then((response) => { setLocations(response.data.data) });
  }

  const handleOnSubmit = (ev) => {
    ev.preventDefault();
    getLocations(zip).then((response) => { setLocations(response.data.data) });
  }

  const handleZipCodeOnChange = (ev) => {
    if (ev.target.value.length >= 5)  {
      setZip(ev.target.value);
    }
  }

  const handleAddToCart = (upc) => {
    redirectToLogin();
    // addProductToCart(upc).then((response) => { console.log(response) });
  }

  return (
    <div className={toggleDrawer ? 'kroger-app drawer--is-open' : 'kroger-app'}>
      <div>
        <div>
          {defaultStore?.addressLine1 && (
            <>
              <p>{defaultStore.addressLine1}, {defaultStore.city}, {defaultStore.state} {defaultStore.zipCode}</p>
            </>
          )}
        </div>
        <div>
          <button onClick={handleDrawer}>Change Store</button>
        </div>
      </div>
      <div className="swiper-wrapper">
        {products && products.map((product) => {
          return (
            <div className="swiper-slide" key={product.productId}>
              <div className="product__tile">
                <img src={product.images[0].sizes[3].url} alt={product.description} />
                {product.description}
                <button onClick={() => handleAddToCart(product.upc)}>Add To Cart</button>
              </div>
            </div>
          )
        })}
      </div>
      <div className={toggleDrawer ? 'drawer drawer--active' : 'drawer'}>
        <button onClick={handleDrawer}>X</button>
        <div>Shop Your Favorite Store</div>
        <div>
          <form onSubmit={handleOnSubmit}>
            <input type="text" onBlur={handleZipCode} value={zip && zip} onChange={handleZipCodeOnChange} />
          </form>
          <div><button onClick={handleGeoLocation}>Use My Location</button></div>
        </div>
        <ul className="kgroger-app__locations">
          {locations && locations.map((item) => {
            return (
              <li key={item.locationId} className={item.locationId === location ? 'active' : undefined}>
                <div>
                  <p><img src={`https://developer.kroger.com/assets/logos/ralphs.svg`} alt={cleanName(item.name)} /></p>
                  <p>{cleanName(item.name)}</p>
                  <p>{item.address.addressLine1}</p>
                  <p>{item.address.city}, {item.address.state} {item.address.zipCode}</p>
                </div>
                <div>
                  {item.locationId !== location ? (
                    <div>
                      <button onClick={() => handleLocationChange(item.locationId)}>Select</button>
                      <p>{getDistanceInMiles(item.geolocation.latitude, item.geolocation.longitude, zip)}</p>
                    </div>
                  ) : (
                    <div>My Store</div>
                  )}
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  );
}

export default App;
