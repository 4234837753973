import { useState, useCallback } from 'react';

export function useObjectState(initialState) {
  const [state, _setState] = useState(initialState);
  const setState = useCallback(
    partialStateOrCallback => {
      if (typeof partialStateOrCallback === 'function') {
        /** parameter is a callback */
        return _setState(partialStateOrCallback);
      }
      /** parameter is a partial state object */
      const partialState = partialStateOrCallback;
      return _setState(prevState => ({ ...prevState, ...partialState }));
    },
    [_setState]
  );
  return { state, setState };
};

export function cleanName(name) {
  const newValue = name.split('-');
  return newValue[0].trim();
};