import axios from 'axios';
import base64 from 'base-64';

export const getAccessToken = () => {
  const CLIENT_ID = 'chefslife-96b51ce9a773c8372735b7559a92ae7a4398614513188052515';
  const CLIENT_SECRET = '6n6JmMTmTL0JEg7H6tXHtJoliMmBAIZvfGQjuu47';
  const accessToken = base64.encode(`${CLIENT_ID}:${CLIENT_SECRET}`);
  return axios.post('https://api.kroger.com/v1/connect/oauth2/token',{}, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Basic ${accessToken}`
    },
    params: {
      'grant_type': 'client_credentials',
      'scope': 'product.compact'
    }
    })      
    .then((response) => {
      return response.data.access_token;
    })
    .catch((error) => {
      console.log(error)
    })
}