import axios from 'axios';
import { getAccessToken } from './index';

export async function getLocations(zipCode) {
  let accessToken = await getAccessToken();
  let locationUrl = `https://api.kroger.com/v1/locations?filter.zipCode.near=${zipCode}&filter.radiusInMiles=100&filter.department=23`;
  if (accessToken) {
    return await axios.get(locationUrl, {
      headers: {
        'Cache-Control': 'no-cache',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    }).then((response) => {
      return response
    });
  }
}

export async function getLocation(locationId) {
  let accessToken = await getAccessToken();
  let locationUrl = `https://api.kroger.com/v1/locations/${locationId}`;
  if (accessToken) {
    return await axios.get(locationUrl, {
      headers: {
        'Cache-Control': 'no-cache',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    }).then((response) => {
      return response;
    });
  }
}
