import axios from 'axios';

export function getZipCodeFromGeoLocation() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const lat = position.coords.latitude;
        const long = position.coords.longitude;
        const endpoint = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + long + "&key=AIzaSyDkhoFTb8p4gpKXtnasuP-F3aU7puvYvE0";
        axios.get(endpoint).then((response) => {
          resolve(response.data.results[0]?.address_components[7]?.long_name);
        });
      }, err => {
        console.warn(`ERROR(${err.code}): ${err.message}`);
      });
    } else {
      reject('There was an error retrieving your data');
    }
  });
}

export function getDistanceInMiles(lat1, lon1, zip) {
  getLatLongFromZipCode(zip).then((response) => {
    const lat2 = response.lat2;
    const lon2 = response.lon2;
    if ((lat1 === lat2) && (lon1 === lon2)) {
      return 0;
    }
    else {
      const radlat1 = Math.PI * lat1/180;
      const radlat2 = Math.PI * lat2/180;
      const theta = lon1-lon2;
      const radtheta = Math.PI * theta/180;
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
          dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = dist * 60 * 1.1515;
      return dist;
    }
  });
}

async function getLatLongFromZipCode(zip) {
  const endpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${zip}&key=AIzaSyDkhoFTb8p4gpKXtnasuP-F3aU7puvYvE0`;
  return await axios.get(endpoint).then((response) => {
    const latitude = response?.data?.results[0]?.geometry?.location?.lat;
    const longitude = response?.data?.results[0]?.geometry?.location?.lng;
    return {
      lat2: latitude,
      lon2: longitude
    }
  });
}