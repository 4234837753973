import { clientId, redirectUrl } from '../config';

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function deleteCookie(name, path, domain) {
  if (getCookie(name)) {
    document.cookie = name + "=" +
      ((path) ? ";path="+path:"")+
      ((domain)?";domain="+domain:"") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

export const redirectToLogin = () => {
  const scope = encodeURIComponent('product.compact cart.basic:write profile.compact');
  const url = `https://api.kroger.com/v1/connect/oauth2/authorize?client_id=${encodeURIComponent(clientId)}&redirect_uri=${encodeURIComponent(redirectUrl)}&response_type=code&scope=${scope}`;
  window.location = url;
}

export function handleCallback() {
  const accessToken = getCookie('accToken');
  const refreshToken = getCookie('refToken');

  if (!accessToken) {
      return false;
  }

  localStorage.setItem('accToken', accessToken);
  localStorage.setItem('refToken', refreshToken);

  deleteCookie('accToken');
  deleteCookie('refToken');

  return true;
}