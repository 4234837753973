
import axios from 'axios';
import { getAccessToken } from './index';

export async function getProducts() {
  const accessToken = await getAccessToken();
  const locationId = sessionStorage.getItem('locationId');
  let searchByLocation = '';
  if (locationId) {
    searchByLocation = `filter.locationId=${locationId}&`;
  }
  const productsUrl = `https://api.kroger.com/v1/products?${searchByLocation}&filter.term=Oil&filter.limit=3`;
  if (accessToken) {
    return await axios.get(productsUrl, {
      crossDomain: true,
      headers: {
        'Cache-Control': 'no-cache',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    }).then((response) => {
      console.log(response.data.data);
      return response.data.data;
    });
  }
}
